body {
  --primary-text-color: var(--color-mud_black);
  --positive-color: var(--color-success);
  --negative-color: var(--color-error);
  --primary-border-color: var(--color-explosive);
  --placeholder-color: var(--color-explosive);
  --icon-color: var(--color-explosive);
  --icon-color-hover: var(--color-blackish);
  --primary-active-color: var(--color-basic_blue);
  --secondary-background-color: #f6f7fb
}

.dark {
  --primary-text-color: #d8d8d8;
  --positive-color: green;
  --negative-color: red;
  --primary-border-color: black;
  --placeholder-color: black;
  --icon-color: black;
  --icon-color-hover: grey;
  --primary-background-color: #484861;
  --primary-background-hover-color: #4b4e69;
  --link-color: var(--color-explosive);
  --secondary-background-color: #33354b
}


.hacker_theme {
  --primary-text-color: #d8d8d8;
  --positive-color: green;
  --negative-color: red;
  --primary-border-color: black;
  --placeholder-color: black;
  --icon-color: black;
  --icon-color-hover: grey;
  --primary-background-color: #484861;
  --link-color: var(--color-explosive);
  --primary-background-hover-color: #4b4e69;
  --primary-color: #fe78c6;
  --primary-hover-color: #fe5ab9;
  --secondary-background-color: #313343;
}


.black {
  --primary-text-color: #d8d8d8;
  --positive-color: green;
  --negative-color: red;
  --primary-border-color: black;
  --placeholder-color: black;
  --icon-color: black;
  --icon-color-hover: grey;
  --primary-background-color: #484861;
  --primary-background-hover-color: #4b4e69;
  --link-color: var(--color-explosive);
  --secondary-background-color: #272729
}

@tailwind base;
@tailwind components;
@tailwind utilities;